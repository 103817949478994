import { useRef } from 'react';
import { NAME } from '@app/account/constants';
import { useTranslation } from 'next-i18next';

// UI
import { cn } from '@utils/cn';
import Emoji from '../Emoji';

// Types
import type { FieldRenderProps } from 'react-final-form';
import type { ChangeEvent } from 'react';

export interface Props extends FieldRenderProps<any> {
    placeholder?: string;
    icon?: Element | string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const TextInput = ({ input, meta, placeholder, icon, onChange, children }: Props) => {
    const { t } = useTranslation(NAME);
    const inputRef = useRef(null);

    const wrapperClass = cn('w-full bg-transparent px-6 py-4', {
        ['pb-0']: meta.error && meta.touched,
    });

    const handleFocus = () => {
        inputRef.current?.focus();
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(event);
        input?.onChange && input.onChange(event);
    };

    return (
        <div className={wrapperClass} onClick={handleFocus}>
            <div className="flex">
                <div className="relative mr-4 w-5 min-w-[20px]">
                    {icon && (
                        <div className="absolute bottom-0 left-0 right-0 top-0 w-full">
                            {typeof icon === 'string' && (
                                <Emoji
                                    symbol={icon}
                                    className="my-auto mt-0.5 flex items-center text-[20px] leading-[20px]"
                                />
                            )}
                            {icon && typeof icon !== 'string' && <>{icon}</>}
                        </div>
                    )}
                </div>

                <input
                    {...input}
                    ref={inputRef}
                    type={input.type}
                    placeholder={placeholder}
                    onChange={handleChange}
                    className="ring-none w-full text-base placeholder-gray-400 outline-none"
                    data-cy={input.name}
                />

                {children}
            </div>
            {meta.error && meta.touched && (
                <div
                    className={`pb-4 ${icon ? 'pl-9' : 'pl-0'} pt-1 text-xs text-yellow-500`}
                    data-cy={`${input.name}-error`}
                >
                    {t(meta.error)}
                </div>
            )}
        </div>
    );
};

export default TextInput;
